<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">HP滤波分析结果</h1>
            <div class="result-area" id="varsProduce">
                <divider-title name="变量解释" />
                <div v-if="result&&result.hp&&result.hp.variableProduce">
                    <div v-for="(item,key) in result.hp.variableProduce" :key="key">{{item.key}}{{item.value}}</div>
                </div>
            </div>

            <div class="result-area" id="descStats">
                <divider-title name="H-P滤波" />
                (Lambda={{result.hp.lumda}})
                <chart :chartData="result.lineChart" />
            </div>            
        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
import Chart from './components/Chart.vue';
export default {
    components:{DividerTitle,Chart},
    computed:{
        result() {
            let data = getSessionItem('analysis_hp');
            return data.analysis_hp;
        },
        parts() {
            let arr = [
                {name: '变量解释', keys: ['varsProduce']},
                {name: 'H-P滤波', keys: ['descStats']},
            ]
            return arr;
        },        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
    }
}
</script>